<template>
    <div class="fixed is-flex is-justify-content-space-between  bg-dark-navbar">
        <div>
            <a style=" color: #E5E5E5;" href="#"><img width="32px" src="../assets/NavBar-images/LOGO.svg" alt=""></a>
        </div>
        <div class="is-hidden-touch nav-bar-text-igualdad" style="">
            <a style=" color: #E5E5E5; margin-right: 40px;" href="#sobreProyecto">Acerca</a>
            <a style=" color: #E5E5E5; margin-right: 40px;" href="#programacion">Programación</a>
            <a style=" color: #E5E5E5; margin-right: 40px;" href="#galeria">Galería</a>
            <a style=" color: #E5E5E5;" href="#footer">Contacto</a>
        </div>
        <div class="is-hidden-desktop" style="width: unset" >


            <div class="dropdown is-right is-active">
                <div class="dropdown-trigger">
                    <a @click="showMenuClick" aria-haspopup="true" aria-controls="dropdown-menu3">
                        <img src="../assets/NavBar-images/barra-hamburguesa.svg" alt="">
                    </a>
                </div>
                <div v-if="showMenu" class="dropdown-menu" id="dropdown-menu3" role="menu">
                    <div class="dropdown-content">
                    <a href="#sobreProyecto" class="dropdown-item">
                        Acerca
                    </a>
                    <a href="#programacion" class="dropdown-item">
                        Programación
                    </a>
                    <a href="#galeria" class="dropdown-item">
                        Galería
                    </a>
                    <a href="#footer" class="dropdown-item">
                        Contacto
                    </a>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    components: {

    },
    data() {
        return {
            showMenu: false,

        }

    },
    methods: {
        showMenuClick() {
            this.showMenu = !this.showMenu
        }
    }
}
</script>

<style>
.bg-dark-navbar {
    background: #131721!important;
    box-shadow: 0px 6.6px 9.9px rgba(17, 17, 17, 0.15);
}
.fixed {
    position: fixed;
    display: block;
    height: 60px;
    align-items: center;
    width: 100vw;
    padding: 0 30px 0 30px;
    z-index: 10;
}
.dropdown-content {
    background: #131721;
    box-shadow: 0px 6.6px 9.9px rgba(17, 17, 17, 0.15);
    margin-top: 20px;

    text-align: center;
}
.dropdown-item {
    color: #E5E5E5;
    font-size: 11px;
    font-weight: bold;
    font-family: Poppins;
    margin-bottom: 10px;
    text-align: center;
}
a.dropdown-item {
    padding-right: 1rem!important;
}
.display-none {
    display: none;
}





</style>