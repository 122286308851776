<template>
  <div>
    <!-- navbar -->
    <div
      class="is-flex is-justify-content-space-between nav-bar-igualdad"
      style="z-index: 15; min-width: 300px"
    >
      <div style="margin-top:15px">
        <a style="color: #e5e5e5" href="#">
          <img width="32px" src="../assets/NavBar-images/LOGO.svg" alt="" />
        </a>
      </div>
      <div class="is-hidden-touch nav-bar-text-igualdad" style="">
        <a style="color: #e5e5e5; margin-right: 40px" href="#sobreProyecto"
          >Acerca</a
        >
        <a style="color: #e5e5e5; margin-right: 40px" href="#programacion"
          >Programación</a
        >
        <a style="color: #e5e5e5; margin-right: 40px" href="#galeria"
          >Galería</a
        >
        <a style="color: #e5e5e5" href="#footer">Contacto</a>
      </div>
      <div class="is-hidden-desktop" style="width: unset;">
        <div class="dropdown is-right is-active">
          <div class="dropdown-trigger">
            <a
              @click="showMenuClick"
              aria-haspopup="true"
              aria-controls="dropdown-menu3"
            >
              <img src="../assets/NavBar-images/barra-hamburguesa.svg" alt="" />
            </a>
          </div>
          <div
            v-if="showMenu"
            class="dropdown-menu bg-light-navBar"
            id="dropdown-menu3"
            role="menu"
          >
            <div class="dropdown-content">
              <a href="#sobreProyecto" class="dropdown-item"> Acerca </a>
              <a href="#programacion" class="dropdown-item"> Programación </a>
              <a href="#galeria" class="dropdown-item"> Galería </a>
              <a href="#footer" class="dropdown-item"> Contacto </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- navbar fixed -->
    <div style="">
      <NavBarFixed v-if="navFixed" />
    </div>
    <!-- header -->

    <div class="emitus-video" style="position: relative">
      <div class="">
        <b-progress
          id="progressbar"
          class="front"
          style="display: none; margin-bottom: -3px"
          :type="type"
          :size="size"
          :max="100"
          @change="saludar"
        ></b-progress>

        <video
          style="width: 100vw; min-width: 300px"
          :src="'https://player.vimeo.com/external/543545953.hd.mp4?s=e5c50f506314ab2a1de94882e3805d9873ef9f00&profile_id=174&oauth2_token_id=1431958713'"
          ref="bgvid"
          id="bgvid"
          playsinline
          muted
          autoplay
          loop
        ></video>

        <!-- filter remove -->
        <div
          v-if="filterRemove"
          class="video-height-video-desktop filter-video-landing"
        >
          <div class="m-center content-filter">
            <div class="">
              <img id="" class="is-45x45 btn-play" src="" />

              <div style="text-align: center" class="">
                <h4 class="text-header-igualdad" style="">
                  Titereando para vivir en igualdad
                </h4>
                <div style="margin-left: 15%;
    margin-right: 15%;"> <p style="font-size:22px;     font-weight: 200;
    margin-top: 20px;
}">Talleres de títeres basados en cuentos infantiles</p> </div>
              </div>
            </div>
          </div>
        </div>
        <!-- filter remove end-->

        <div class="filter">
          <div class="">
            <div
              id="load"
              @click="displayProgressBar"
              class="invisible"
              onclick="document.getElementById('bgvid').currentTime = 0;
                        document.getElementById('load').style.display = 'none';
                        document.getElementById('pause').style.display = 'block';
                        document.getElementById('progressbar').style.display = 'block';
                        document.getElementById('bgvid').muted = !document.getElementById('bgvid').muted"
            >
              <div class="center is-horizontal-center">
                <figure class="image is-64x64">
                  <img src="../assets/header-images/invisible.png" />
                </figure>
              </div>
            </div>
            <!-- NO BORRAR !!!! -->
            <!-- <div
                id="pause"
                style="display: none"
                class="invisible"
                @click="stopVideo"
                onclick="document.getElementById('bgvid').pause();
                        document.getElementById('pause').style.display = 'none';
                        document.getElementById('play').style.display = 'block';"
                >
                <img src="" />
              </div> -->

            <div
              id="play"
              style="display: none"
              @click="displayProgressBar"
              class="invisible"
              onclick="document.getElementById('bgvid').play();
                        document.getElementById('play').style.display = 'none';
                        document.getElementById('pause').style.display = 'block';
                        "
            >
              <div class="center is-horizontal-center">
                <figure class="image is-64x64">
                  <img src="" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- icon under video  -->
      <div class="has-text-centered icon-under" style="">
        <a href="#sobreProyecto">
          <img src="../assets/header-images/icon-arrow-header.svg" alt="" />
        </a>
      </div>
      <div class="has-text-right icon-whatsapp">
        <a
          href="https://api.whatsapp.com/send?phone=+34649146612"
          target="_blank"
        >
          <img src="../assets/header-images/icon-W.svg" alt="" />
        </a>
      </div>
    </div>

    <!-- sobre proyecto -->

    <div id="sobreProyecto" class="found-right" style="z-index: 10">
      <div class="display-flex">
        <div class="w-content-proyect-50">
          <div class="content-section-one">
            <div>
              <img
                class="img-title-mobile"
                src="https://res.cloudinary.com/dzneqacw6/image/upload/q_auto/v1619213079/menu/bgdrx9moojgf0homhnrf.svg"
                alt="Sobre el proyecto"
              />
            </div>
            <div class="content-p-proyect">
              <p>
                <b> =da </b> es un proyecto cultural de artes escénicas para promover la  coeducación. A través de distintas obras de teatro con títeres y marionetas reflexionaremos acerca de los estereotipos y roles de género,  así como éstos contribuyen a perpetuar la desigualdad y la violencia de género.  Los tradicionales cuentos infantiles fomentan la desigualdad a través de la repetición de obsoletos estereotipos y roles que alimentan los mitos del amor romántico, relaciones de dependencia y sumisión,  falta de empoderamiento de las niñas y creencias de superioridad en los niños. Todo ello apuntala el desequilibrio relacional.
              </p>
              <p style="margin-top: 49px">
                Urgen modelos alternativos y más libres de educación infantil que permitan a las niñas y los niños salir de las cárceles mentales impuestas por rancios modelos de ser y estar en el mundo. Urge un cambio de paradigma y se debe empezar por el presente, para tener un mejor futuro, educando en la igualdad, el respeto, la diversidad y la tolerancia, la empatía y la compasión  a través de las artes escénicas.
              </p>
            </div>
          </div>
        </div>

        <div class="w-content-proyect-50 found-mobile">
          <div class="content-img-proyect">
            <div>
              <img
                class="img-proyect-mobile"
                src="https://res.cloudinary.com/dzneqacw6/image/upload/q_auto/v1619208791/menu/ohv3cspnst2qzou4tt1g.png"
                alt="image1"
              />
            </div>
            <div>
              <img
                class="img-proyect-mobile img-decoration-mobile"
                style="position: relative; margin-left: -20px"
                src="../assets/sobreProyecto/image-decoration1.svg"
                alt="decoration"
              />
            </div>
            <div class="img-last-content-mobile">
              <img
                class="img-proyect-mobile"
                style="margin-top: -45px"
                src="https://res.cloudinary.com/dzneqacw6/image/upload/q_auto/v1619211417/menu/ss5nv4kp3nci4jdyny6g.png"
                alt="image2"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="seccion-proyect-yellow" style="z-index: 10">
        <div class="display-flex" style="width: 70%; margin: 0 auto">
          <img
            class="seccion-proyect-yellow-img"
            src="../assets/sobreProyecto/image-decoration2.svg"
            alt=""
          />

          <div class="seccion-proyect-yellow-content">
            <p>
              “No nos damos cuenta de que si no permitimos ni fomentamos que un niño pueda jugar con muñecos y casas de muñecas, el día de mañana no va a ser un padre implicado y no va a asumir los cuidados y la corresponsabilidad en el hogar como propia. Y si nuestras niñas no juegan a ser superheroínas, no creerán que tienen la capacidad de cambiar el mundo”
            </p>
            <p
              class="is-hidden-mobile"
              style="margin-top: 50px; font-size: 15px; color: #fffff"
            >
              Iria Marañòn
            </p>
          </div>
        </div>
      </div>
    </div>

    <div id="" style="z-index: 0">
      <NuestrosObjetivos />
    </div>
    <div id="programacion">
      <Programacion />
    </div>
    <div id="galeria">
      <Galeria />
    </div>
    <div id="footer">
      <Footer />
    </div>
  </div>
</template>

<script>
import NuestrosObjetivos from "../components/NuestrosObjetivos";
import Programacion from "../components/Programacion";
import Galeria from "../components/Galeria";
import Footer from "../components/Footer";
import NavBarFixed from "../components/NavBarFixed";

var nIntervId = undefined;
export default {
  components: {
    NuestrosObjetivos,
    Programacion,
    Galeria,
    Footer,
    NavBarFixed,
  },
  data() {
    return {
      navFixed: false,
      showMenu: false,
      filterRemove: true,
      indeterminate: false,
      type: "is-primary",
      size: "is-small",
      nIntervId: 0,
      sizes: "is-small",
      packs: "mdi",
      icons: "star",
      score: false,
      text: false,
      isSpaced: true,
      isDisabled: true,
      isLoading: false,
      isFullPage: false,
    };
  },

  created() {
    console.log("created");
    // this.getUser();
    console.log(this.$store.getters["user/kUser"]);
    this.openLoading();
  },

  mounted() {
    window.addEventListener("scroll", this.onScroll);
    console.log("mounted");
    this.displayProgressBar();
    this.filterRemove = true;
  },
  methods: {
    showMenuClick() {
      this.showMenu = !this.showMenu;
    },

    onScroll() {
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      console.log(currentScrollPosition);
      console.log(this.navFixed);
      if (currentScrollPosition > 680) {
        this.navFixed = true;

        // // NO BORRAR !!!!

        // this.filterRemove = true
        // document.getElementById("bgvid").pause();
        // document.getElementById('load').style.display = 'none';
        // document.getElementById('pause').style.display = 'none';
        // document.getElementById('play').style.display = 'block';
        // document.getElementById('callToAction').style.display = 'block';
        // document.getElementById('callToActionTop').style.display = 'none';
      } else {
        this.navFixed = false;
      }
    },
    pauseVideoScrolled() {
      const resp = kAuth.pauseVideoScroll();
      return;
    },
    saludar(event) {
      this.displayProgressBar();
      this.filterRemove = true;
    },

    displayProgressBar: function () {
      // // NO BORRAR !!!!
      // this.filterRemove = false
      // const v = this;
      // this.nIntervId = setInterval(function () {
      //   var duration = document.getElementById("bgvid").duration;
      //   var currentTime = document.getElementById("bgvid").currentTime;
      //   v.valueVideo = Math.round((currentTime / duration) * 100);
      // }, 500);
    },
    stopVideo: function () {
      // // NO BORRAR !!!!
      // this.filterRemove = true
      // const v = this;
      // console.log("Stop!");
      // clearInterval(this.nIntervId);
    },

    handleScroll() {
      console.log("Scroll . . . . ");
    },

    openLoading() {
      this.filterRemove = true;
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
      }, 10 * 200);
    },
  },
};
</script>

<style>
* {
  scroll-behavior: smooth;
}
.bg-light-navBar .dropdown-content {
  background: linear-gradient(
    180deg,
    rgba(138, 138, 138, 0) 0%,
    rgba(24, 24, 24, 0.529688) 57.03%,
    rgba(0, 0, 0, 0.9) 100%
  );
}
.nav-bar-text-igualdad {
  color: #e5e5e5;
  font-size: 14px;
  font-weight: 500;
  font-family: Poppins;
  margin-right: 50px;
  margin-top: 10px;
}
.icon-whatsapp {
  position: fixed;
  z-index: 20;
}
.seccion-proyect-yellow-content p {
  font-family: Podkova;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
}

.content-section-one {
  max-width: 730px;
  width: 90%;
  margin: auto;
  
}

/* desktop */
@media (min-width: 1024px) {
  .icon-whatsapp {
    right: 0px;
    bottom: 0px;
  }
  .nav-bar-igualdad {
    height: 60px;
    background: transparent;
    align-items: center;
    width: 100vw;
    position: absolute;
    padding: 0 30px 0 30px;
  }
  .text-header-igualdad {
    color: #f3f3f3;
    font-size: 50px;
    line-height: 60px;
    font-weight: 500;
    font-family: Poppins;
    margin-top: 0px;
    width: 60%;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
  }
  .imagen-header-igualdad {
    background-image: url("../assets/header-images/imagen-header.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100vh;
  }
  .content-header-igualdad {
    width: 80%;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
  }
  .found-right {
    width: 100%;
    background-image: url("../assets/sobreProyecto/found-right-p.png");
    background-position: right;
    background-repeat: no-repeat;
    background-size: 65%;
    height: 100%;
  }
  .display-flex {
    display: flex;
    
  }
  .content-p-proyect {
    font-size: 16px;
    line-height: 38px;
    color: #393939;
    font-weight: 400;
    font-family: Rufina;
    width: 70%;
    margin: 0 auto;
    padding-top: 59px;
    letter-spacing: 0.2px;
  }
  .w-content-proyect-50 {
    width: 50%;
    max-width: 730px;
  }
  .content-img-proyect {
    width: 70%;
    margin: 0 auto;
    margin-top: 265px;
  }
  .seccion-proyect-yellow {
    background: linear-gradient(283.99deg, #622db3 33.19%, #511992 90.18%);
    /*background-color: #6678EF;*/
    height: 460px;
    width: 85%;
    margin-top: 13%;
  }
  .seccion-proyect-yellow-content {
    height: 100%;
    color: #ffffff;
    font-size: 17px;
    line-height: 191.7%;
    margin-top: 120px;
  }
  .seccion-proyect-yellow-img {
    margin-bottom: 170px;
    padding-right: 20px;
  }
}

@media (max-width: 1150px) {
  .seccion-proyect-yellow-content {
    height: 100%;
    color: #ffffff;
    font-size: 17px;
    line-height: 191.7%;
    margin-top: 130px;
  }

  .seccion-proyect-yellow-content p {
    font-size: 18px;
  }
}
/* @media (min-width: 768px) and (max-width: 1024px) {
      .icon-whatsapp {
        height: 100vh;
        top: 70%;
        right: 0;
    }
} */

/* mobile */
@media (max-width: 1024px) {
  .icon-whatsapp {
    right: 0px;
    bottom: 0px;
  }

  .btn-play {
    margin-top: 100px;
  }

  .nav-bar-igualdad {
    height: 60px;
    background: transparent;
    align-items: center;
    width: 100vw;
    position: absolute;
    padding: 0 40px 0 40px;
  }
  .text-header-igualdad {
    color: #f3f3f3;
    font-size: 25px;
    line-height: 37px;
    font-weight: 500;
    font-family: Poppins;
    margin-top: 70px;
    width: 60%;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
  }
  .imagen-header-igualdad {
    background-image: url("../assets/header-images/imagen-header-mobile.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 639px;
  }
  .content-header-igualdad {
    width: 80%;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 233px;
  }
  .icon-igualdad-header {
    padding-top: 70px;
  }

  .seccion-proyect-yellow-content p {
    font-size: 16px;
  }

  .found-mobile {
    width: 100%;
    background: #FADEF6;
    border-start-start-radius: 100px;
    margin-top: 240px;
  }
  .content-p-proyect {
    font-size: 15px;
    line-height: 28px;
    color: #393939;
    font-weight: 400;
    font-family: Rufina;
    width: 93%;
    margin: 0 auto;
    padding-top: 90px;
  }
  .content-img-proyect {
    padding-top: 165px;
    max-width: 730px;
    margin: auto;
  }
  .img-proyect-mobile {
    width: 100vw;
    
  }
  .img-title-mobile {
    width: 85%;
    max-width: 370px;
  }
  .img-decoration-mobile {
    width: 30%;
    max-width: 111px;
    height: 28%;
    margin-top: 70px;
  }
  .seccion-proyect-yellow {
    background: linear-gradient(283.99deg, #622db3 33.19%, #511992 90.18%);
    margin-top: -10px;
    width: 100%;
  }
  .seccion-proyect-yellow-content {
    color: #ffffff;
    font-size: 17px;
    line-height: 191.7%;
    margin-top: 15px;
    padding-bottom: 90px;
  }
  .seccion-proyect-yellow-img {
    margin-top: 30px;
  }
  .img-last-content-mobile {
    padding-bottom: 170px;
  }
}

/* VIDEO */

.filter {
  width: 100%;
  height: 100vh;
  z-index: 10;
  position: absolute;
  top: 0px;
  opacity: 0;
  background: #b400ff2b;
}

.invisible {
  width: 100%;
  height: 100vh;
  z-index: 10;
  top: 0px;
  opacity: 0;
  background-color: #0d123900;
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  margin-top: -75px;
}

.is-horizontal-center {
  justify-content: center;
}

.emitus-video video {
  max-width: 100%;
  height: 100vh;
  object-fit: cover;
}
.filter-video-landing {
  position: absolute;
  top: 0;
}
.content-filter {
  color: #ffffff;
  display: flex;
  height: 100%;
  align-items: center;
  align-content: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  padding-top: 150px;
  background-color: #40015a91;
}
.content-video-center h4 {
  margin-top: 100px;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  width: 367px;
}

.icon-under {
  position: absolute;
  bottom: 10px;
  width: 100%;
  color: rgba(255, 255, 255, 0.8);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  z-index: 20;
}

@media only screen and (min-width: 959px) {

  .content-filter {

  background-color: #40015a69;
}

  .center {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 540px;
    margin-top: 0px;
  }

  .is-horizontal-center {
    justify-content: center;
  }

  .hero-video {
    display: block;
    left: -1px;
  }

  .emitus-video video {
    object-fit: cover;
  }

  .hero-body {
    padding: 0;
  }

  .hero.is-fullheight {
    background-color: #0d1239;
  }

  .control {
    max-width: 100%;
  }

  .filter {
    width: 100%;
    height: 100vh;
    z-index: 10;
    position: absolute;
    top: 0;
    opacity: 1;
    background-color: #00000000;
    border-radius: 0px;
    overflow: hidden;
  }

  .invisible {
    width: 100%;
    height: 100vh;
    z-index: 10;
    top: 0px;
    opacity: 1;
    background-color: transparent;
  }

  .video-mask {
    border-radius: 0px;
    overflow: hidden;
    height: 100vh;
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .filter-video-landing {
    height: 100vh;
    
    z-index: 2;
    width: 100%;
    position: absolute;
    top: 0;
  }
  .filter-invisible {
    height: 100vh;
    /* background-color: thistle; */
    background: transparent;
    width: 100%;
    z-index: 2;
  }
  /* .filter-invisible {
    height: 100%;
    background-color: thistle;
    background: transparent;
    width: 100%;
    z-index: 1; 
    position: absolute;
  
  } */
  /* .filter-video {
    width: 100vw;
    height: 100%;
    z-index: 1;
    position: absolute; */
  /* top: 138px; */
  /* opacity: 1;
    background-color: rgba(13, 18, 57, 0.2); */
  /* background-color: transparent; */
  /* border-radius: 20px; */
  /* overflow: hidden; */
  .content-video-center h4 {
    margin-top: 90px;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 36px;
    text-align: left;
    width: 271px;
  }
  .visible-desktop {
    display: none;
    visibility: hidden;
  }
}

/* ---DESKTOP--- */

@media (min-width: 1024px) {
  .filter-video-landing {
    height: 100vh;
    background: rgb(65 0 86 / 60%);
    z-index: 2;
    width: 100%;
  }
  .filter-invisible {
    height: 100%;
    /* background-color: thistle; */
    background: transparent;
    width: 100%;
    z-index: 2;
    position: relative;
  }
  .icon-under {
    bottom: 40px;
  }
}
@media (min-width: 1025px) {
  .visible-desktop {
    display: block;
  }
}
</style>