<template>
    <div>
        <img class="logo-galery" src="../assets/galeria/logo-galeria.svg" alt="">
        <div class="container-galery-desktop container-galery-mobile">
            <div class="grid-galery-desktop img-galery" id='info'>

            </div>
            
            <!-- <div class="grid-galery-desktop is-hidden-touch" id='info'>

            </div>
            <div class="is-hidden-desktop" id='info-mobile'>

            </div> -->
            <!-- <div class="galery-more">
                <a class="" href="#">ver más <img src="../assets/galeria/next.svg" alt=""></a>
            </div> -->
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    components: {
        axios
    },
    methods: {
       
       
       
    
    showFeed() {
        let settings = {
            // esto es el contenido de el feed IG
                  "url": "https://v1.nocodeapi.com/devance/instagram/tOOzLkPhkbghxejl",
                  "method": "GET",
                  "timeout": 0,
                  "headers": {
                    "Content-Type": "application/json"
                  },
                };
            axios.get("https://v1.nocodeapi.com/devance/instagram/tOOzLkPhkbghxejl",
        
            {
               headers: {
                    "Content-Type": "application/json",
                  },
            } )
            .then((result) => {
                console.clear
                const datos = JSON.stringify(result.data.data)
    
                let datosObj = JSON.parse(datos)
                console.log(datosObj)

                let imgId =  document.getElementById("info")
                imgId.innerHTML = '';
                 
                for(let item of datosObj) {
                    console.log(item.media_url)
                    const img = item.media_url
                    imgId.innerHTML +=  "<img src='"+img+"'>" 
                
                }      
                
        })


        },
    },
    created: function(){
        this.showFeed()
    }
}
</script>

<style>
.galery-more a {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 108.2%;
    text-decoration-line: underline;
    color: #985389;
}
.galery-more img {
    margin-left: 11px;
}
#es-new #es-header .es-free {
    display: none;
}
.powered-by > a {
    display:none;
}
.es-powered-by a {
    display: none;
}



/* mobile-tablet */
@media (max-width: 1024px) {
    .container-galery-mobile {
        width: 90%;
        margin: 0 auto;
        margin-bottom: 150px;
    }
    .logo-galery {
        margin-bottom: 145px;
        margin-left: 5%;
    }
    .img-galery {
        margin-bottom: 25px;
    }
    .galery-more {
        padding: 50px 0 170px 0;
    }
    .img-galery img {
        margin-bottom: 25px;
    }
}



/* desktop */
@media (min-width: 1024px) {
    .container-galery-desktop {
        width: 80%;
        margin: 0 auto;
        margin-bottom: 300px;
    }
    .grid-galery-desktop {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem;
    }
    .logo-galery {
        margin-bottom: 50px;
        margin-left: 5%;
    }

    .galery-more {
        padding: 35px 0 280px 0;
    }

}


    @media screen and (min-width: 768px) {
       
        #es-new .f-reorder.f-reorder-large {
            width: calc(33% - 15px);

    }
    #es-new .f-reorder {
    width: calc(33% - 15px);
    }
    }
</style>