<template>
  <div>
    <div class="section-two">
      <div style="width: 100%">
        <img
          class="is-hidden-desktop mb-6"
          src="../assets/programacion/logo-mobile.svg"
          alt=""
        />
        <div class="container-card-desktop is-hidden-touch">
          <img
            class="programacion-title"
            style="margin-bottom: 66px; margin-top: 300px"
            src="../assets/programacion/logo-desktop.svg"
            alt=""
          />
        </div>
      </div>
      <div class="grid-desktop-card container-card-desktop">
        <!-- card -->
        <div class="container-card-mobile card-mb-mobile card-mb-desktop">
          <div class="bg-card-1 img-card">
            <div class="content-img-card">
              <h3>"LA PRINCIPE Y EL PRINCESA"</h3>
              <p>
                Esta temática que nos invita a reflexionar sobre los estereotipos y roles de género, el sexismo y la discriminación.
              </p>
            </div>
          </div>
          <div class="content-card">
            <p class="card-subtitle">Fechas:</p>
            <p class="card-date mb-2">
              <span style="font-weight: bold"> 16/05/2021 </span>
              PIRÁMIDE MERCADILLO DEL AGRICULTOR, LA MATANZA DE ACENTEJO, 11:30 horas
            </p>
            <br>
            <p class="card-date mb-6">
              <span style="font-weight: bold"> 23/05/2021 </span> ESPACIO CULTURAL LA CASONA, SANTA ÚRSULA 12:00 horas
            </p>

            <p class="card-subtitle">Sipnosis</p>
            <p class="card-p-content">
               Durante la fiesta de su nacimiento, un hada se enfrenta al Tirano Rey para que eduque a sus hijos en igualdad de condiciones; pero ante tales profecías decide separarlos para siempre, enviando a la princesa a una torre rosa y al príncipe a una torre azul. Después de 15 años y con ayuda de los músicos del reino, príncipe y princesa se cambian los papeles, el príncipe será El princesa y la princesa será La príncipe. ¿Se dará cuenta el reino de tan alocada situación?, ¿Lograrán demostrar al reino que príncipe o princesa, las oportunidades, para todos, son iguales?.

            </p>
            <!-- <div class="card-btn card-btn-text">
              <a
                class="card-btn-text"
                href="https://api.whatsapp.com/send?phone=+34649146612"
                >Reservar</a
              >
            </div> --> 
          </div>
        </div>
        <!-- fin card -->

        <!-- card-2 -->
        <div class="container-card-mobile card-mb-mobile card-mb-desktop">
          <div class="bg-card-2 img-card">
            <div class="content-img-card">
              <h3>"NAIRA, MI HEROÍNA FAVORITA"</h3>
              <p>
                Con Naira aprenderemos a romper estereotipos y roles de género y a establecer referentes de  empoderamiento femenino.
              </p>
            </div> 
          </div>
          <div class="content-card">
            <p class="card-subtitle">Fechas:</p>
            <div class="card-date mb-6">
              <span style="font-weight: bold"> 14/05/2021 </span>CEIP, LA MATANZA DE ACENTEJO,<span> 9:25 horas </span>
              <br>
              <br>
              <span style="font-weight: bold"> 21/05/2021 </span>BIBLIOTECA MUNICIPAL DE SANTA ÚRSULA, <span > 17:00 horas </span>

            </div>

            <p class="card-subtitle p-top-card-content">Sipnosis</p>
            <p class="card-p-content">
              Naira es una niña de origen guanche que significa “maravillosa”. Naira es curiosa, inquieta, vital y muy valiente y junto a sus amigas  va a vivir grandes aventuras porque… ¡son heroínas y quieren cambiar el mundo!
              <br>
              Espectáculo para niñas y niños de aprox. 10 años
            </p>
<!--            <div class="card-btn card-btn-text">
              <a
                class="card-btn-text"
                href="https://api.whatsapp.com/send?phone=+34649146612"
                >Reservar</a
              >
            </div> -->
          </div>
        </div>
        <!-- fin card -->
        <!-- card-3 -->
        <div class="container-card-mobile card-mb-mobile card-mb-desktop-dark">
          <div class="bg-card-3 img-card">
            <div class="content-img-card"> 
              <h3>"NESTOR, COLA DE SIRENA"</h3>
              <p>
                Un cuento que nos enseña a cuestionar el binarismo de género. 
              </p>
            </div>
          </div>
          <div class="content-card">
            <p class="card-subtitle">Fechas:</p>
            <p class="card-date mb-6">
              <span style="font-weight: bold"> 19/05/2021 </span> BIBLIOTECA MUNICIPAL DE SANTA ÚRSULA,
              <span style="font-weight: bold"> 17:00 horas</span>
            </p>

            <p class="card-subtitle p-top-card-content">Sipnosis</p>
            <p class="card-p-content">
              Néstor es un niño muy feliz al que le gusta ser quien es. A veces,  le gusta vestirse con trajes y sombreros de su madre, también le gusta ponerse tutú o una cola de sirena. Nadie parece comprenderlo, pero la Luna le ayudará a entender que no tiene que ser como otros digan, no tiene que elegir, sólo hacer aquello que realmente le haga sentirse bien consigo mismo.
              <br>
              Espectáculo para niñas y niños de aprox. 6 años
            </p>
            <!-- <div class="card-btn card-btn-text">
              <a
                class="card-btn-text"
                href="https://api.whatsapp.com/send?phone=+34649146612"
                >Reservar</a
              >
            </div> -->
          </div>
        </div>
        <!-- fin card -->
        <!-- card-bg-dark 1-->
        <div class="card-mb-mobile card-mb-desktop-dark">
          <div class="card-bg-dark container-card-mobile">
            <div class="bg-card-4 img-card">
              <div class="content-img-card">
                <h3>"FIESTA DE CUMPLEAÑOS"</h3>
                <p>
                  Historia que trata sobre la identidad de género y las personas
                  trans.
                </p>
              </div>
            </div>
            <div class="content-card" style="padding-bottom: 95px">
              <p class="card-subtitle-dark">Fechas:</p>
              <p class="card-date-dark mb-6">
                <span style="font-weight: bold"> 13/05/2021 </span> CEIP, LA MATANZA DE ACENTEJO,<span style="font-weight: bold"> 9:25 horas </span>
              </p>

              <p class="card-subtitle-dark p-top-card-content">Sipnosis</p>
              <p class="card-p-content-dark">
                Mario se sentía diferente al resto de los niños pues si bien
                había nacido en el cuerpo de un niño, se sentía niña. A Mario le
                gustaban las cosas consideradas de “niña” como bailar, el pelo
                largo y usar faldas. Su familia no entendía, al principio, lo
                que le pasaba a Mario pero con ayuda, tolerancia y mucho amor
                comprenderán que, simplemente, Mario era persona trans y quería ser
                considerado niña.
                <br>
                Espectáculo para niñas y niños de aprox. 8 años
              </p>
              <!-- <div class="card-btn card-btn-text">
                <a
                  class="card-btn-text"
                  href="https://api.whatsapp.com/send?phone=+34649146612"
                  >Reservar</a
                >
              </div> -->
            </div>
          </div>
          <div
            class="has-text-right img-card-decoration-mobile img-card-decoration-desktop"
            style=""
          >
            <img src="../assets/programacion/decoration-card.svg" alt="" />
          </div>
        </div>
        <!-- fin card -->
        <!-- card-bg-dark -->
        <div class="card-mb-mobile card-mb-desktop-dark is-hidden-touch">
          <div class="card-bg-dark container-card-mobile">
            <div class="bg-card-5 img-card">
              <div class="content-img-card">
                <h3>"INDOMABLE"</h3>
                <p>
                  Es un canto al amor por la naturaleza y los animales pero, sobre todo, es un canto a la libertad, la tolerancia, la valentía y la autonomía personal.
                </p>
              </div>
            </div>
            <div class="content-card" style="padding-bottom: 95px">
              <p class="card-subtitle-dark">Fechas:</p>
              <p class="card-date-dark mb-2">
                <span style="font-weight: bold"> 12/05/2021 </span>
                CEIP, ATALAYA LA MATANZA DE ACENTEJO, 9:25 horas
              </p>
              <br>
              <p class="card-subtitle-dark">Sipnosis</p>
              <p class="card-p-content-dark">
                Luna vive en el bosque rodeada de sus amigos, los animales. Pájaro Picón le enseñò a hablar, Oso Meloso le enseñó a cazar y Zorro Plateado a esconderse para evitar ciertos peligros, de Conejo Conejin aprendió a comer saludablemente, a tener una mente sana y a cuidar su espíritu y, lo que es más importante, a agradecer. Y, aunque algunas personas se empeñen en intentar que deje de ser quien es, Luna había nacido para ser libre y eso era algo a lo que no quería renunciar. Esta era su propia naturaleza...¿ cómo evitar que el sol deje de brillar?
              </p>
              <!-- <div class="card-btn card-btn-text">
                <a
                  class="card-btn-text"
                  href="https://api.whatsapp.com/send?phone=+34649146612"
                  >Reservar</a
                >
              </div> --> 
            </div>
          </div>
          <div
            class="has-text-right img-card-decoration-mobile img-card-decoration-desktop"
            style=""
          >
            <img src="../assets/programacion/decoration-card.svg" alt="" />
          </div>
        </div>
        <!-- fin card -->
        <!-- card-2 -->
        <div class="container-card-mobile card-mb-mobile card-mb-desktop-dark">
          <div class="bg-card-6 img-card">
            <div class="content-img-card">
              <h3>"CORAZÓN DE CRISTAL"</h3>
              <p>
                Hablemos de coraje, superación personal, empoderamiento femenino y de profesiones masculinizadas y segregación horizontal.
              </p>
            </div>
          </div>
          <div class="content-card">
            <p class="card-subtitle">Fechas:</p>
            <p class="card-date mb-2">
              <span style="font-weight: bold"> 20/05/2021 </span>
              BIBLIOTECA MUNICIPAL DE SANTA ÚRSULA, 17:00 horas
            </p>
            <br>
            <p class="card-subtitle">Sipnosis</p>
            <p class="card-p-content">
              Sheny quería ser artesana vidriera como su padre, sin embargo, éste consideraba que era un oficio exclusivamente para hombres. Sheny, con apoyo de sus amigos Tito y Elisa, iniciará un viaje para conseguir su sueño, demostrándole a su padre que se equivoca y que puede conseguir cualquier cosa que se proponga.
              <br>
              Espectáculo para niñas y niños de aprox. 8 años
            </p>
            <!-- <div class="card-btn card-btn-text">
              <a
                class="card-btn-text"
                href="https://api.whatsapp.com/send?phone=+34649146612"
                >Reservar</a
              >
            </div> --> 
          </div>
        </div>
        <!-- fin card -->
        <!-- card-bg-dark -->
        
        <!-- fin card -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
};
</script>

<style>
.card-subtitle {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 108.2%;
  color: #131721;
  margin-bottom: 18px;
  font-family: Poppins;
}
.card-date {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 153.7%;
  color: #131721;
}
.card-p-content {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 202.7%;
  color: #393939;
  font-family: Rufina;
  width: 95%;
  max-width: 500px;
  margin: auto;
}

.programacion-title {
  max-width: 420px;
}

.card-subtitle-dark {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 108.2%;
  color: #ffffff;
  margin-bottom: 18px;
  font-family: Poppins;
}
.card-date-dark {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 153.7%;
  color: #ffffff;
}
.card-p-content-dark {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 202.7%;
  color: #ffffff;
  font-family: Rufina;
  width: 80%;
}

.bg-card-1 {
  background-image: url("https://res.cloudinary.com/dzneqacw6/image/upload/q_auto/v1619412915/menu/syfxwczcyte2kgdr2csd.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.bg-card-2 {
  background-image: url("https://res.cloudinary.com/dzneqacw6/image/upload/q_auto/v1619418143/menu/zyvjv2f9rhkpuitgz0kt.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.bg-card-3 {
  background-image: url("https://res.cloudinary.com/dzneqacw6/image/upload/q_auto/v1619418281/menu/pzl7eaffrflpnlbytdkx.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.bg-card-4 {
  background-image: url("https://res.cloudinary.com/dzneqacw6/image/upload/q_auto/v1619443554/menu/tabbmobzkkxvveysgmwi.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-card-5 {
  background-image: url("https://res.cloudinary.com/dzneqacw6/image/upload/q_auto/v1619444759/menu/pgl7tjmeti3ebmlofbmv.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-card-6 {
  background-image: url("https://res.cloudinary.com/dzneqacw6/image/upload/q_auto/v1619444880/menu/n4ogzxhfb1acdnocplin.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.img-card {
  height: 334px;
  display: flex;
  align-items: flex-end;
}
.content-img-card {
  color: #ffffff;
  margin-left: 26px;
  padding-bottom: 26px;
  width: 80%;
}
.content-img-card h3 {
  font-family: Poppins;
}
.content-img-card p {
  font-style: normal;
  font-weight: normal;
  font-size: 13.0469px;
  line-height: 168.7%;
  font-family: Rufina;
}
.content-card {
  width: 90%;
  margin: 0 auto;
  padding-top: 34px;
}
.card-btn {
  width: 125px;
  height: 38px;
  left: 152px;
  top: 7010px;
  background: linear-gradient(90.29deg, #6678ef -9.23%, #6678ef 96.52%);
  border-radius: 11px;
  margin-top: 34px;
}
.card-btn-text {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  color: #ffffff;
  text-align: center;
  align-content: center;
  align-items: center;
  padding-top: 10px;
  font-family: Poppins;
}
.card-bg-dark {
  background: #131721;
}

.section-two {
  
  margin: auto;
  margin-top: 120px;
}

/* mobile-tablet */
@media (max-width: 1024px) {
  .section-two {
    width: 100%;
    max-width: 730px;
    margin: auto;
    margin-top: 120px;
  }

  .is-hidden-desktop {
    width: 100%;
    max-width: 415px;
  }

  .content-img-card h3 {
    width: 230px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 119.7%;
    margin-bottom: 7px;
  }
  .card-mb-mobile {
    margin-bottom: 170px;
  }
  .img-card-decoration-mobile {
    margin-top: -100px;
    margin-right: 10px;
  }
}

/* desktop */
@media (min-width: 1024px) {
  .container-card-desktop {
    width: 80%;
    margin: 0 auto;
  }
  .grid-desktop-card {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }
  .card-mb-desktop {
    margin-bottom: 170px;
  }
  .card-mb-desktop-dark {
    margin-bottom: 90px;
  }
  .img-card-decoration-desktop {
    margin-top: -100px;
    padding-right: 10px;
  }

  .content-img-card h3 {
    width: 230px;
    font-weight: bold;
    margin-bottom: 26px;
    font-size: 18px;
  }
  .content-img-card p {
    width: 361px;
    font-size: 16px;
  }
  .p-top-card-content {
    padding-top: 45px;
  }
}
</style>