<template>
<div>

    <div style="width: 100%;" class="ourObj-bg-bubbles" >

        <div class="ourObj-flex">

            <div class="ourObj-w-85 ourObj-bg-85 ">
                <div class="ourObj-flex " style="z-index: -2;">
                    <div class="ourObj-w-50 ourObj-bg-50 bg-mobile-content-1">
                            <div class="containter-title-mobile">

                                <h3 class="ourObj-content-title" >Nuestros objetivos</h3>
                            </div>
                        <div class="container-mobile-content">
                            <ul>
                                <li class="ourObj-content-1">Valorar de forma positiva la igualdad, el afecto, la ayuda y el respeto en las relaciones con las demás personas.</li>
                                <li class="ourObj-content-2">Fomentar el respeto a las diversas expresiones de género</li>
                                <li class="ourObj-content-3">Mostrar referentes femeninos alternativos</li>
                            </ul>
                        </div>

                    </div>
                    <div class="ourObj-w-50 bg-mobile-content-2">
                        <div class="ourObj-content-right">
                            <div class="container-mobile-content">
                                <ul>
                                    <li value="4" class="ourObj-content-4" style="">Desarrollar la imaginación</li>
                                    <li class="ourObj-content-5" style="">Favorecer la asimilación de las diferencias como algo positivo y  enriquecedor</li>
                                    <li class="ourObj-content-6" >Reforzar la autoestima de las niñas y favorecer su empoderamiento personal</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="img-decoration-top-right ">
                <img src="../assets/NuestrosObjetivos/decoration-top-right.svg" alt="">
            </div>
        </div>
        <div class="ourObj-img-family pl-5 hidde-tablet">
            <img class="img-decoration-family" src="../assets/NuestrosObjetivos/decoration-bottom-left.svg" alt="">
            <img class="is-relative" width="625px" src="https://res.cloudinary.com/dzneqacw6/image/upload/q_auto/v1619412438/menu/bxo9apa1hgrjsy0sfatd.png" alt="">
        </div>
        <div class="is-hidden-desktop has-text-right mt-5 mr-5  ">
            <img src="../assets/NuestrosObjetivos/decoration-bubbles-mobile-p.png" alt="">
        </div>
    </div>
        <div class="ourObj-flex ourObj-logos">
            <img class="img-logos" src="https://res.cloudinary.com/dzneqacw6/image/upload/q_auto/v1620012880/menu/snqo5bcochqzm5zhzjya.png" alt="">
            <p class="description-patrocinio">Este proyecto ha sido subvencionado por el  Cabildo de Tenerife, con la colaboración del Ayuntamiento de Santa Úrsula, del Ayuntamiento de La Matanza y Tragaluz Teatro de Títeres.</p>
            <img class="is-hidden-touch mb-6 " src="../assets/NuestrosObjetivos/decoration-desktop-rig.png" alt="">
        </div>
</div>
</template>

<script>
export default {
    components: {}
}
</script>

<style>
ul li {
    list-style-type: decimal;
    padding-left: 18px;
    font-family: Rufina;
}
.ourObj-content-title {
    font-family: Poppins;
    font-weight:bold
}
.ourObj-logos p {
    font-family: Rufina;
}

.description-patrocinio {
    font-size: 16px;
    max-width: 730px;
}


@media (min-width: 1024px) {
    ul li::marker {
        font-size: 35px;
        color: #393939;
        font-family: Poppins;
        font-weight: 600;
    }
    .ourObj-bg-bubbles {
        background-image: url("../assets/NuestrosObjetivos/decoration-bubbles-p.png");
        background-position: right bottom;
        background-repeat: no-repeat;
        background-size: contain;
    }
    .ourObj-w-85 {
        width: 85%;
    }
    .ourObj-w-50 {
        width: 50%;
    }
    .ourObj-bg-85 {
        background: #d34ffe2e;
        border-bottom-right-radius: 349px;
        height: 900px;
    }
    .ourObj-bg-50 {
        background: #e1caff;
        border-top-right-radius: 295px;
        height: 1118px;
    }
    .ourObj-flex {
        display: flex;
    }
    .ourObj-content-title {
        width: 180px; 
        font-size: 30px; 
        line-height: 138.7%; 
        color: #393939;
        margin: 115px auto 97px 120px;
    }
    .ourObj-content-1 {
        width: 70%;
        margin: 0 0 97px auto;
        padding-right: 97px;
    }
    .ourObj-content-2 {
        width: 65%;
         margin: 0 0 97px auto;
         padding-right: 93px;
    }
    .ourObj-content-3 {
        width: 55%;
         margin: 0 0 97px auto;
        padding-right: 70px;
    }
    .ourObj-content-right {
        width: 50%;
        margin: 300px auto 152px 100px;

    }
    .ourObj-content-4 {
        padding-bottom: 130px;
    }
    .ourObj-content-5 {
        padding-bottom: 105px;
    }
    /* .ourObj-content-6 {

    } */
    .img-decoration-top-right {
        margin-top: -9px;
        margin-left: -9px;
    }
    .ourObj-img-family {
        padding-top: 160px;
        position: relative;
        bottom: 168px;
        width: 85%;
        margin: 0 auto;
     
    }
    .img-decoration-family {
        position: absolute;
        top: 80px;
        left: -65px;
    }

    .img-logos {
        max-width: 350px;
    }
    
    .ourObj-logos {
        width: 80%;
        margin: 0 auto;
        margin-top: 230px;
        align-items: center;
    }
    .ourObj-logos p {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 259.19%;
        text-align: right;
        color: #393939;
        width: 380px;
        margin: 0 auto;
        padding-left: 20px;
    }
    
}



@media (max-width: 1024px) {
    ul li::marker {
        font-size: 26px;
        color: #393939;
        font-family: Poppins;
        font-weight: 600;
        padding-right: 50px;
    }
    .hidde-tablet {
        display: none;
    }
    .bg-mobile-content-1 {
       background: #e6d9ff;
       border-top-right-radius: 100px;
       
    }
    .bg-mobile-content-2 {
        background: #FADEF6;
        border-bottom-right-radius: 100px;
    }
    .ourObj-flex {
        position: relative;
    }
    .img-decoration-top-right {
        position: absolute;
        top: -20px;
        right: 0;    
        z-index: -1;    
    }
    .container-mobile-content {
        width: 60%;
        margin: 0 auto;
        
    }
    .container-mobile-content ul li{
        font-size: 18px;
        font-family: Rufina;
        
    }
    .ourObj-content-title {
        color: #393939;
        font-style: normal;
        font-weight: 600;
        font-size: 19.7143px;
        line-height: 138.7%;
        width: 118.29px;
        padding-top: 66px;
    }
    .container-mobile-content  ul li {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 166.7%;
        color: #393939;
        padding-bottom: 60px;
    }
    .containter-title-mobile {
        width: 75%;
        margin: 0 auto;
        margin-bottom: 80px; 
    }
    .ourObj-content-right {
        padding-top: 50px;
    }
    .ourObj-logos {
        width: 80%;
        margin: 0 auto;
        margin-top: 79px;
        text-align: center;
    } 
    .ourObj-logos p {
        font-family: Rufina;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 259.19%;
        text-align: center;
        color: #393939;
        margin-top: 73px;
        max-width: 730px;

    }

    .img-logos {
        max-width: 350px;
    }
}

</style>